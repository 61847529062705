import BaseApi from "../../../services/Api";
import Swal from "sweetalert2";
import React, {useEffect, useState} from "react";
import Tooltip from "../../Popups/Tooltip";
import {Modal, Spinner} from "react-bootstrap";
import { format } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR';


const ModalPartnerInfo = ({
  idPartner, children
}) => {
  const [isLoading, setLoading] = React.useState(true);
  const [isSaving, setSaving] = React.useState(true);
  const [isShow, setShowModal] = React.useState(false);

  const [name, setName] = useState('');
  const [creationDate, setCreationDate] = useState('');
  const [image, setImage] = useState(null);


  const handleClose = () => {
    setShowModal(false);
  }

  const requestData = () => {
    setLoading(true);
    BaseApi.get(`/partners/${idPartner}`).then(res => {
      let data = res.data;
      setName(data.name);
      setImage(data.logo);
      setCreationDate(data.created_at);
      setLoading(false);
      setSaving(false);
    }).catch(err => {
      console.log(err);
      Swal.fire('Oops!', err?.data?.errors?.[0] || err?.data?.message || 'Ocorreu um erro ao carregar este parceiro ou o parceiro não foi encontrado.', 'error');
      setShowModal(false);
    })
  }

  useEffect(() => {
    if(isShow) {
      requestData();
    } else {
      setName('');
      setImage(null);
      setLoading(true);
      setSaving(true);
    }
  }, [isShow])
  return (
    <>
      {children &&
        React.cloneElement(children, { onClick: (e) => setShowModal(true) })}
      {!children && (
        <Tooltip text={"Informações do Parceiro"}>
          <button
            className={`btn btn-${idPartner ? "warning" : ""} text-white fa-bold`}
            onClick={(e) => setShowModal(true)}
          >
            <i className={`bi bi-${idPartner ? "person-gear" : "plus"}`}></i>
          </button>
        </Tooltip>
      )}
      <Modal
        centered
        scrollable
        onHide={handleClose}
        show={isShow}
        animation={true}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Informações do Parceiro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center">
            {isLoading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
          {!isLoading && (
            <>
              <div className="row">
                
                <div className="form-group mb-3">
                  <label htmlFor="name">Nome</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Insira o nome"
                    value={name}
                  readonly/>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="creationDate">Data de criação</label>
                  <input
                    type="creationDate"
                    className="form-control"
                    id="creationDate"
                    value={format(new Date(creationDate), "d 'de' MMMM 'de' yyyy 'às' H:mm", { locale: ptBR })}
                   
                  readonly/>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="image">Imagem</label>
                  {idPartner ? (
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      <img
                        src={image instanceof File ? URL.createObjectURL(image) : image}
                        alt="imagem"
                        style={{ minWidth: 250, width: 350, objectFit: 'cover' }}
                      />
                    </div>
                  ) : 
                    null
                  }

                  {image && !idPartner && (
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      {idPartner ? <img src={image} alt="imagem" style={{minWidth: 250, width: 350, objectFit: "cover"}}/>
                        : <img src={URL.createObjectURL(image)} alt="imagem" style={{minWidth: 250, width: 350, objectFit: "cover"}}/>
                      }
                      </div>
                    )
                  }
                </div>


              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center w-100">
            <div className="d-block me-auto"></div>
            <div className="d-block ms-auto">
              <button
                className="btn btn-danger text-white me-2"
                onClick={handleClose}
              >
                Close
              </button>
             
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalPartnerInfo;