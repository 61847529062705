import { useEffect, useState } from "react"
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

const defaultModules = {
    toolbar: [
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link'],        
    ],
}

const defaultFormats = [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link',
]

function TextEditor({ initialValue, onChange, readonly = false }) {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        if (onChange) onChange(value)
    }, [value])

    useEffect(() => {
      setValue(initialValue); 
    }, [initialValue])

    return <ReactQuill 
        theme="snow"
        readOnly={readonly}
        value={value} 
        onChange={setValue}
        modules={defaultModules}
        formats={defaultFormats}
    />;
}

export default TextEditor