import BaseApi from "../../../services/Api";
import { imageCompress } from "../../../services/ImageCompress";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import Tooltip from "../../Popups/Tooltip";
import { Modal, Spinner } from "react-bootstrap";
import Button from "../../Layout/Button";
import style from "./style.module.css";

const ModalTestimony = ({ idTestimony, onUpdate, onCreate, children }) => {
  const [isLoading, setLoading] = React.useState(true);
  const [isSaving, setSaving] = React.useState(true);
  const [isShow, setShowModal] = React.useState(false);

  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [role, setRole] = useState("");
  const [image, setImage] = useState(null);
  const [updateImage, setUpdateImage] = useState(null);
  const [errors, setErrors] = useState({});

  const buildFormData = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("text", text);
    formData.append("role", role);

    if (idTestimony) {
      formData.append("_method", "PUT");
      if (updateImage) {
        formData.append("image", await imageCompress(updateImage));
      }
    } else {
      if (image) {
        formData.append("image", await imageCompress(image));
      }
    }
    console.log(formData);
    return formData;
  };

  const submitData = () => {
    setSaving(true);
    buildFormData().then((formData) => {
      if (idTestimony) {
        BaseApi.post(`/testimonies/${idTestimony}`, formData)
          .then((res) => {
            setSaving(false);
            setShowModal(false);
            toast.success("Depoimento criado com sucesso!");
            onUpdate && onUpdate(res.data);
          })
          .catch((err) => {
            setErrors(err.response.data.errors);
            Swal.fire(
              "Oops!",
              err?.data?.errors?.[0] ||
                err?.data?.message ||
                "Ocorreu um erro ao atualizar este depoimento.",
              "error"
            );
            setSaving(false);
          });
      } else {
        BaseApi.post("/testimonies", formData)
          .then((res) => {
            setSaving(false);
            setShowModal(false);
            toast.success("Depoimento atualizado com sucesso");
            onCreate && onCreate(res.data);
          })
          .catch((err) => {
            setErrors(err.response.data.errors);
            Swal.fire(
              "Oops!",
              err?.data?.errors?.[0] ||
                err?.data?.message ||
                "Ocorreu um erro ao criar este depoimento.",
              "error"
            );
            setSaving(false);
          });
      }
    });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleChangeImage = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      if (idTestimony) {
        setUpdateImage(selectedImage);
      }
      setImage(selectedImage);
    }
  };

  const requestData = () => {
    setLoading(true);
    BaseApi.get(`/testimonies${idTestimony ? `/${idTestimony}` : ""}`)
      .then((res) => {
        if (idTestimony) {
          let data = res.data;
          setName(data.name);
          setText(data.text);
          setRole(data.role);
          setImage(data.image);
        }
        setLoading(false);
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Oops!",
          err?.data?.errors?.[0] ||
            err?.data?.message ||
            "Ocorreu um erro ao carregar este depoimento ou não foi encontrado.",
          "error"
        );
        setShowModal(false);
      });
  };

  useEffect(() => {
    if (isShow) {
      requestData();
    } else {
      setName("");
      setText("");
      setRole("");
      setImage(null);
      setUpdateImage(null);
      setLoading(true);
      setSaving(true);
      setErrors({});
    }
  }, [isShow]);

  return (
    <>
      {children &&
        React.cloneElement(children, { onClick: (e) => setShowModal(true) })}
      {!children && (
        <Tooltip text={idTestimony ? "Editar depoimento" : "Criar depoimento"}>
          <button
            className={`btn btn-${
              idTestimony ? "warning" : ""
            } text-white fa-bold`}
            onClick={(e) => setShowModal(true)}
          >
            <i className={`bi bi-${idTestimony ? "person-gear" : "plus"}`}></i>
          </button>
        </Tooltip>
      )}
      <Modal
        centered
        scrollable
        onHide={handleClose}
        show={isShow}
        animation={true}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {idTestimony ? "Editar" : "Criar"} depoimento
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center">
            {isLoading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
          {!isLoading && (
            <>
              <div className="row">
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="name">
                    Nome
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Insira o nome"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    maxLength={80}
                  />
                  {errors?.name && (
                    <p style={{ color: "red" }}>{errors?.name.slice(-1)}</p>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="role">
                    Cargo
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="role"
                    placeholder="Insira o cargo"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    maxLength={80}
                  />
                  {errors?.role && (
                    <p style={{ color: "red" }}>{errors?.role.slice(-1)}</p>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="text">
                    Texto
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="text"
                    placeholder="Digite o Texto"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    maxLength={200}
                  ></textarea>
                  {errors?.text && (
                    <p style={{ color: "red" }}>{errors?.text.slice(-1)}</p>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="image">
                    Imagem
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    onChange={handleChangeImage}
                  />
                  {errors?.image && (
                    <p style={{ color: "red" }}>{errors?.image.slice(-1)}</p>
                  )}
                  {idTestimony ? (
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      <img
                        src={
                          image instanceof File
                            ? URL.createObjectURL(image)
                            : image
                        }
                        alt="imagem"
                        style={{
                          minWidth: 250,
                          width: 350,
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  ) : null}
                  {image && !idTestimony && (
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      {idTestimony ? (
                        <img
                          src={image}
                          alt="imagem"
                          style={{
                            minWidth: 250,
                            width: 350,
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(image)}
                          alt="imagem"
                          style={{
                            minWidth: 250,
                            width: 350,
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center w-100">
            <div className="d-block me-auto"></div>
            <div className="d-block ms-auto">
              <button
                className="btn btn-danger text-white me-2"
                onClick={handleClose}
              >
                Close
              </button>
              <Button
                loading={isSaving}
                onClick={submitData}
                className="btn btn-success text-white"
              >
                {idTestimony ? "Editar" : "Criar"}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalTestimony;
