import React from "react";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import style from "./style.module.css";
import styles from "./styles.module.css";
import { useOutletContext } from "react-router-dom";
import Button from "../../../components/Layout/Button";

import BaseApi from "../../../services/Api";
import { imageCompress } from "../../../services/ImageCompress";

import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { findIndex } from "lodash";
import { Spinner } from "react-bootstrap";
import { format, set } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

export default function AboutUs() {
  let [searchParams, setSearchParams] = useSearchParams();

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [isSaving, setSaving] = React.useState(true);
  const [isShow, setShowModal] = React.useState(false);
  const [neverRequested, setNeverRequested] = React.useState(true);
  const loggedUser = useOutletContext();

  const [idAboutUs, setIdAboutUs] = useState("");
  const [description, setDescription] = useState("");
  const [mission, setMission] = useState("");
  const [vision, setVision] = useState("");
  const [values, SetValues] = useState("");
  const [numberProjects, SetNumberProjects] = useState("");
  const [image, setImage] = useState(null);
  const [updateImage, setUpdateImage] = useState(null);
  const [errors, setErrors] = useState({});

  let controller = new AbortController();

  const requestData = () => {
    setLoading(true);
    BaseApi.get("/about-us", {
      signal: controller.signal,
    })
      .then((response) => {
        setForm(response.data);
        setNeverRequested(false);
        setLoading(false);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          toast.error("Erro ao carregar dados da tabela");
          setLoading(false);
        }
      });
  };

  const setForm = (data) => {
    setIdAboutUs(data.id);
    setDescription(data.description);
    setMission(data.mission);
    setVision(data.vision);
    SetValues(data.values);
    SetNumberProjects(data.number_projects);
    setImage(data.image);
  };

  const handleChangeImage = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      setUpdateImage(selectedImage);
      setImage(selectedImage);
    }
  };

  const buildFormData = async () => {
    const formData = new FormData();
    formData.append("description", description);
    formData.append("mission", mission);
    formData.append("vision", vision);
    formData.append("values", values);
    formData.append("number_projects", numberProjects);

    if (idAboutUs) {
      formData.append("_method", "PUT");
    } else {
      formData.append("_method", "POST");
    }
    if (updateImage) {
      formData.append("image", await imageCompress(updateImage));
    }

    return formData;
  };

  const submitData = () => {
    Swal.fire({
      title: "Tem certeza que deseja editar Sobre Nós?",
      text: "Essa ação não pode ser revertida!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Editar!",
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        let route = "/about-us/";
        setLoading(true);
        if (idAboutUs) {
          route += idAboutUs;
        }
        buildFormData().then((formData) => {
          BaseApi.post(route, formData)
            .then((res) => {
              setErrors({});
              setSaving(false);
              setShowModal(false);
              requestData();
              setIsEditing(false);
              toast.success("Sobre nós atualizado com sucesso!");
            })
            .catch((err) => {
              setErrors(err.response.data.errors);
              Swal.fire(
                "Oops!",
                err?.data?.errors?.[0] ||
                  err?.data?.message ||
                  "Ocorreu um erro ao atualizar sobre nós.",
                "error"
              );
            })
            .finally(() => {
              setLoading(false);
            });
        });
      }
    });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    requestData();
  };

  useEffect(() => {
    controller = new AbortController();
    requestData();
    return () => {
      controller.abort();
      setLoading(true);
    };
  }, []);

  return (
    <>
      <div className="d-flex flex-column">
        <div className={"d-flex flex-column flex-md-row pb-4"}>
          <div className={`${styles.user} col-12 col-md-4 mb-2`}>
            <h1>Sobre Nós</h1>
          </div>
        </div>
        {isLoading || neverRequested ? (
          <div className="d-flex align-items-center justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="form-group mb-3">
                <label className={style.required} htmlFor="description">
                  Descrição{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Insira a descrição"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  readOnly={isEditing ? "" : "readonly"}
                />
                {errors?.description && (
                  <p style={{ color: "red" }}>{errors?.description.slice(-1)}</p>
                )}
              </div>
              <div className="form-group mb-3">
                <label className={style.required} htmlFor="mission">
                  Missão{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="mission"
                  placeholder="Insira a missão"
                  value={mission}
                  onChange={(e) => setMission(e.target.value)}
                  readOnly={isEditing ? "" : "readonly"}
                />
                {errors?.mission && (
                  <p style={{ color: "red" }}>{errors?.mission.slice(-1)}</p>
                )}
              </div>
              <div className="form-group mb-3">
                <label className={style.required} htmlFor="vision">
                  Visão{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="vision"
                  placeholder="Insira a visão"
                  value={vision}
                  onChange={(e) => setVision(e.target.value)}
                  readOnly={isEditing ? "" : "readonly"}
                />
                {errors?.vision && (
                  <p style={{ color: "red" }}>{errors?.vision.slice(-1)}</p>
                )}
              </div>
              <div className="form-group mb-3">
                <label className={style.required} htmlFor="values">
                  Valor{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="values"
                  placeholder="Insira o valor"
                  value={values}
                  onChange={(e) => SetValues(e.target.value)}
                  readOnly={isEditing ? "" : "readonly"}
                />
                {errors?.values && (
                  <p style={{ color: "red" }}>{errors?.values.slice(-1)}</p>
                )}
              </div>
              <div className="form-group mb-3">
                <label className={style.required} htmlFor="number_projects">
                  Numero de projetos{" "}
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="number_projects"
                  placeholder="Insira o numero de projetos"
                  value={numberProjects}
                  onChange={(e) => SetNumberProjects(e.target.value)}
                  readOnly={isEditing ? "" : "readonly"}
                />
                {errors?.number_projects && (
                  <p style={{ color: "red" }}>
                    {errors?.number_projects.slice(-1)}
                  </p>
                )}
              </div>
              <div className="form-group mb-3">
                <label className={style.required} htmlFor="image">
                  Imagem{" "}
                </label>
                {isEditing && (
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    onChange={handleChangeImage}
                  />
                )}
                {errors?.image && (
                  <p style={{ color: "red" }}>{errors?.image.slice(-1)}</p>
                )}

                {idAboutUs ? (
                  <div className="d-flex justify-content-center align-items-center mt-2">
                    <img
                      src={
                        image instanceof File
                          ? URL.createObjectURL(image)
                          : image
                      }
                      alt="imagem"
                      style={{ minWidth: 250, width: 350, objectFit: "cover" }}
                    />
                  </div>
                ) : null}

                {image && !idAboutUs && (
                  <div className="d-flex justify-content-center align-items-center mt-2">
                    {idAboutUs ? (
                      <img
                        src={image}
                        alt="imagem"
                        style={{
                          minWidth: 250,
                          width: 350,
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <img
                        src={URL.createObjectURL(image)}
                        alt="imagem"
                        style={{
                          minWidth: 250,
                          width: 350,
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>
                )}
              </div>

              {!isEditing ? (
                  <div className="d-flex justify-content-end">
                  <Button
                    loading={false}
                    onClick={handleEditClick}
                    className="btn btn-warning text-white"
                  >
                    Editar
                  </Button>
                </div>
                ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <div>
                      <Button
                        loading={false}
                        onClick={handleCancelClick}
                        className="btn btn-danger text-white"
                      >
                        Cancelar
                      </Button>
                    </div>
                    <div>
                      <Button
                        loading={false}
                        className="btn btn-success text-white"
                        onClick={submitData}
                      >
                        Salvar
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
