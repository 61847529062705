import BaseApi from "../../../services/Api";
import { imageCompress } from "../../../services/ImageCompress";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import Tooltip from "../../Popups/Tooltip";
import { Modal, Spinner } from "react-bootstrap";
import Button from "../../Layout/Button";
import style from "./style.module.css";
import { auto, left } from "@popperjs/core";

const ModalBanner = ({ idBanner, onUpdate, onCreate, children }) => {
  const [isLoading, setLoading] = React.useState(true);
  const [isSaving, setSaving] = React.useState(true);
  const [isShow, setShowModal] = React.useState(false);
  const [errors, setErrors] = useState({});

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [buttontext, setButtonText] = useState("");
  const [buttonlink, SetButtonLink] = useState("");
  const [image, setImage] = useState(null);
  const [creationDate, setCreationDate] = useState("");
  const [updateImage, setUpdateImage] = useState(null);
  const [bannerButton, setBannerButton] = useState(false);
  const [center, setCenter] = useState(false);

  const buildFormData = async () => {
    const formData = new FormData();
    formData.append("title", title || "");
    formData.append("subtitle", subtitle || "");
    formData.append("button_text", "");
    formData.append("button_link", "");

    if (bannerButton) {
      formData.append("button_check", 1);
    } else {
      formData.append("button_check", 0);
    }

    if (bannerButton) {
      formData.append("button_text", buttontext || "");
      formData.append("button_link", buttonlink || "");
    }

    if (idBanner) {
      formData.append("_method", "PUT");
      if (updateImage) {
        formData.append("image", await imageCompress(updateImage));
      }
    } else {
      if (image) {
        formData.append("image", await imageCompress(image));
      }
    }
    
    if (center) {
      formData.append("center", 1);
    } else {
      formData.append("center", 0);
    }

    return formData;
  };

  const submitData = () => {
    buildFormData().then((formData) => {
      setSaving(true);
      if (idBanner) {
        BaseApi.post(`/banners/${idBanner}`, formData)
          .then((res) => {
            setSaving(false);
            setBannerButton(false);
            setShowModal(false);
            toast.success("Banner atualizado com sucesso!");
            onUpdate && onUpdate(res.data);
          })
          .catch((err) => {
            setErrors(err.response.data.errors);
            Swal.fire(
              "Oops!",
              err?.data?.errors?.[0] ||
                err?.data?.message ||
                "Ocorreu um erro ao atualizar o Banner.",
              "error"
            );
            setSaving(false);
          });
      } else {
        BaseApi.post("/banners", formData)
          .then((res) => {
            setSaving(false);
            setShowModal(false);
            toast.success("Banner criado com sucesso");
            onCreate && onCreate(res.data);
          })
          .catch((err) => {
            setErrors(err.response.data.errors);
            Swal.fire(
              "Oops!",
              err?.data?.errors?.[0] ||
                err?.data?.message ||
                "Ocorreu um erro ao criar o banner.",
              "error"
            );

            setSaving(false);
          });
      }
    });
  };

  const handleClose = () => {
    setShowModal(false);
    setBannerButton(false);
  };

  const handleChangeImage = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      if (idBanner) {
        setUpdateImage(selectedImage);
      }
      setImage(selectedImage);
    }
  };

  const handleButtonCheck = (e) => {
    setBannerButton(e.target.checked);
    if (!e.target.checked) {
      setButtonText("");
      SetButtonLink("");
    }
  };

  const requestData = () => {
    setLoading(true);
    BaseApi.get(`/banners${idBanner ? `/${idBanner}` : ""}`)
      .then((res) => {
        let data = res.data;
        setTitle(data.title);
        setSubtitle(data.subtitle);
        setButtonText(data.button_text);
        SetButtonLink(data.button_link);
        setBannerButton(!!(data.button_text && data.button_link));
        setImage(data.image);
        setCenter(data.center);
        setCreationDate(data.created_at);
        setLoading(false);
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Oops!",
          err?.data?.errors?.[0] ||
            err?.data?.message ||
            "Ocorreu um erro ao carregar este banner ou não foi encontrado.",
          "error"
        );
        setShowModal(false);
      });
  };

  useEffect(() => {
    if (isShow) {
      requestData();
    } else {
      setTitle("");
      setSubtitle("");
      setButtonText("");
      SetButtonLink("");
      setImage(null);
      setUpdateImage(null);
      setCenter(false);
      setLoading(true);
      setSaving(true);
      setErrors({});
    }
  }, [isShow]);

  return (
    <>
      {children &&
        React.cloneElement(children, { onClick: (e) => setShowModal(true) })}
      {!children && (
        <Tooltip text={"Informações Banner"}>
          <button
            className={`btn btn-${
              idBanner ? "warning" : ""
            } text-white fa-bold`}
            onClick={(e) => setShowModal(true)}
          >
            <i className={`bi bi-${idBanner ? "person-gear" : "plus"}`}></i>
          </button>
        </Tooltip>
      )}
      <Modal
        centered
        scrollable
        onHide={handleClose}
        show={isShow}
        animation={true}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Informações Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center">
            {isLoading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
          {!isLoading && (
            <>
              <div className="row">
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="title">
                    Titulo
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Insira o titulo"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {errors?.title && (
                    <p style={{ color: "red" }}>{errors?.title.slice(-1)}</p>
                  )}
                  <p style={{ fontSize: "11px" }}>
                    Para destacar palavras use '{"<"}' (para abertura de
                    destaque) e '{">"}' (para fechamento) .
                  </p>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="subtitle">Subtitulo</label>
                  <input
                    type="text"
                    className="form-control"
                    id="subtitle"
                    placeholder="Insira o subtitulo"
                    value={subtitle}
                    onChange={(e) => setSubtitle(e.target.value)}
                  />
                  {errors?.subtitle && (
                    <p style={{ color: "red" }}>{errors?.subtitle.slice(-1)}</p>
                  )}
                </div>
                <div className="form-group d-flex align-center mb-3">
                  <input 
                    type="checkbox" 
                    checked={center}
                    id="center"
                    style={{ marginRight: "5px", width: "15px" }} 
                    onChange={(e) => setCenter(e.target.checked)} 
                  />
                  {errors?.center && (
                    <p style={{ color: "red" }}>{errors?.center.slice(-1)}</p>
                  )}
                  <label htmlFor="center">Estilização centralizada</label>
                </div>
                <div className="form-group d-flex align-center mb-3">
                  <input
                    type="checkbox"
                    checked={bannerButton}
                    id="bannerbutton"
                    style={{ marginRight: "5px", width: "15px" }}
                    onChange={handleButtonCheck}
                  />
                  <label htmlFor="bannerbutton">O banner terá botão?</label>
                </div>
                {bannerButton ? (
                  <>
                    <div className="form-group mb-3">
                      <label className={style.required} htmlFor="buttontext">
                        Texto do botão
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="buttontext"
                        placeholder="Insira o texto do botão"
                        value={buttontext}
                        onChange={(e) => setButtonText(e.target.value)}
                      />
                      {errors?.button_text && (
                        <p style={{ color: "red" }}>
                          {errors?.button_text.slice(-1)}
                        </p>
                      )}
                    </div>
                    <div className="form-group mb-3">
                      <label className={style.required} htmlFor="buttonlink">
                        Link do botão
                      </label>
                      <input
                        type="url"
                        className="form-control"
                        id="buttonlink"
                        placeholder="Insira o link do botão"
                        value={buttonlink}
                        onChange={(e) => SetButtonLink(e.target.value)}
                      />
                      {errors?.button_link && (
                        <p style={{ color: "red" }}>
                          {errors?.button_link.slice(-1)}
                        </p>
                      )}
                    </div>
                  </>
                ) : null}
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="image">
                    Imagem{" "}
                  </label>

                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    onChange={handleChangeImage}
                  />
                  {errors?.image && (
                    <p style={{ color: "red" }}>{errors?.image.slice(-1)}</p>
                  )}

                  {idBanner ? (
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      <img
                        src={
                          image instanceof File
                            ? URL.createObjectURL(image)
                            : image
                        }
                        alt="imagem"
                        style={{
                          minWidth: 250,
                          width: 350,
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  ) : null}

                  {image && !idBanner && (
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      {idBanner ? (
                        <img
                          src={image}
                          alt="imagem"
                          style={{
                            minWidth: 250,
                            width: 350,
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(image)}
                          alt="imagem"
                          style={{
                            minWidth: 250,
                            width: 350,
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center w-100">
            <div className="d-block me-auto"></div>
            <div className="d-block ms-auto">
              <button
                className="btn btn-danger text-white me-2"
                onClick={handleClose}
              >
                Close
              </button>
              <Button
                loading={isSaving}
                onClick={submitData}
                className="btn btn-success text-white"
              >
                {idBanner ? "Editar" : "Criar"}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalBanner;
