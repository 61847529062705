import BaseApi from "../../../services/Api";
import Swal from "sweetalert2";
import React, {useEffect, useState} from "react";
import Tooltip from "../../Popups/Tooltip";
import {Modal, Spinner} from "react-bootstrap";
import { format } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR';


const ModalidBannerInfo = ({
  idBanner, children
}) => {
  const [isLoading, setLoading] = React.useState(true);
  const [isSaving, setSaving] = React.useState(true);
  const [isShow, setShowModal] = React.useState(false);

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [buttontext, setButtonText] = useState('');
  const [buttonlink, SetButtonLink] = useState('');
  const [image, setImage] = useState(null);
  const [center, setCenter] = useState(false);
  const [creationDate, setCreationDate] = useState('');


  const handleClose = () => {
    setShowModal(false);
  }

  const requestData = () => {
    setLoading(true);
    BaseApi.get(`/banners/${idBanner}`).then(res => {
      let data = res.data;
      setTitle(data.title);
      setSubtitle(data.subtitle);
      setButtonText(data.button_text);
      SetButtonLink(data.button_link);
      setImage(data.image);
      setCenter(data.center);
      setCreationDate(data.created_at);
      setLoading(false);
      setSaving(false);
    }).catch(err => {
      console.log(err);
      Swal.fire('Oops!', err?.data?.errors?.[0] || err?.data?.message || 'Ocorreu um erro ao carregar ou não foi encontrado.', 'error');
      setShowModal(false);
    })
  }

  useEffect(() => {
    if(isShow) {
      requestData();
    } else {
      setTitle('');
      setSubtitle('');
      setButtonText('');
      SetButtonLink('');
      setImage('');
      setCenter(false)
      setLoading(true);
      setSaving(true);
    }
  }, [isShow])

  return (
    <>
      {children &&
        React.cloneElement(children, { onClick: (e) => setShowModal(true) })}
      {!children && (
        <Tooltip text={"Informações Banner"}>
          <button
            className={`btn btn-${idBanner ? "warning" : ""} text-white fa-bold`}
            onClick={(e) => setShowModal(true)}
          >
            <i className={`bi bi-${idBanner ? "person-gear" : "plus"}`}></i>
          </button>
        </Tooltip>
      )}
      <Modal
        centered
        scrollable
        onHide={handleClose}
        show={isShow}
        animation={true}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Informações Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center">
            {isLoading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
          {!isLoading && (
            <>
              <div className="row">

                <div className="form-group mb-3">
                  <label htmlFor="title">Titulo</label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Insira o titulo"
                    value={title}
                  readOnly/>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="subtitle">Subtitulo</label>
                  <input
                    type="text"
                    className="form-control"
                    id="subtitle"
                    placeholder="Insira o subtitulo"
                    value={subtitle}

                  readOnly/>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="buttontext">Texto do botão</label>
                  <input
                    type="text"
                    className="form-control"
                    id="buttontext"
                    placeholder="Insira o texto do botão"
                    value={buttontext}
                  readOnly/>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="buttonlink">Link do botão</label>
                  <input
                    type="text"
                    className="form-control"
                    id="buttonlink"
                    placeholder="Insira o link do botão"
                    value={buttonlink}
                  readOnly/>
                </div>
                <div className="form-group d-flex align-center mb-3">
                  <input
                    type="checkbox" 
                    id="center"
                    style={{ marginRight: "5px", width: "15px" }} 
                    checked={center} 
                  />
                  <label htmlFor="center">Estilização centralizada</label>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="image">Imagem</label>

                
                  
                  {idBanner ? (
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      <img
                        src={image instanceof File ? URL.createObjectURL(image) : image}
                        alt="imagem"
                        style={{ minWidth: 250, width: 350, objectFit: 'cover' }}
                      />
                    </div>
                  ) : 
                    null
                  }

                  {image && !idBanner && (
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      {idBanner ? <img src={image} alt="imagem" style={{minWidth: 250, width: 350, objectFit: "cover"}}/>
                        : <img src={URL.createObjectURL(image)} alt="imagem" style={{minWidth: 250, width: 350, objectFit: "cover"}}/>
                      }
                      </div>
                    )
                  }
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center w-100">
            <div className="d-block me-auto"></div>
            <div className="d-block ms-auto">
              <button
                className="btn btn-danger text-white me-2"
                onClick={handleClose}
              >
                Close
              </button>

            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalidBannerInfo;