import Logo from "../../../assets/images/logo.svg"

const Dashboard = () => {
    return (
    <div style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
      <img src={Logo} alt="" style={{backgroundColor: "#18355D", padding: '1rem 0.5rem', borderRadius: '0.25rem', width: '20rem'}} />
    </div>
  )
}

export default Dashboard;