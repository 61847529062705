import BaseApi from "../../../services/Api";
import Swal from "sweetalert2";
import React, {useEffect, useState} from "react";
import Tooltip from "../../Popups/Tooltip";
import {Modal, Spinner} from "react-bootstrap";
import { format } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR';


const ModalPostCategoryInfo = ({
  idCategory, children
}) => {
  const [isLoading, setLoading] = React.useState(true);
  const [isSaving, setSaving] = React.useState(true);
  const [isShow, setShowModal] = React.useState(false);

  const [title, setTitle] = useState('');
  const [creationDate, setCreationDate] = useState('');


  const handleClose = () => {
    setShowModal(false);
  }

  const requestData = () => {
    setLoading(true);
    BaseApi.get(`/post-category/${idCategory}`).then(res => {
      let data = res.data;
      setTitle(data.title);
      setCreationDate(data.created_at);
      setLoading(false);
      setSaving(false);
    }).catch(err => {
      console.log(err);
      Swal.fire('Oops!', err?.data?.errors?.[0] || err?.data?.message || 'Ocorreu um erro ao carregar esta categoria ou a categoria não foi encontrado.', 'error');
      setShowModal(false);
    })
  }

  useEffect(() => {
    if(isShow) {
      requestData();
    } else {
      setTitle('');
      setLoading(true);
      setSaving(true);
    }
  }, [isShow])

  return (
    <>
      {children &&
        React.cloneElement(children, { onClick: (e) => setShowModal(true) })}
      {!children && (
        <Tooltip text={"Informações do Usuário"}>
          <button
            className={`btn btn-${idCategory ? "warning" : ""} text-white fa-bold`}
            onClick={(e) => setShowModal(true)}
          >
            <i className={`bi bi-${idCategory ? "person-gear" : "plus"}`}></i>
          </button>
        </Tooltip>
      )}
      <Modal
        centered
        scrollable
        onHide={handleClose}
        show={isShow}
        animation={true}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Informações da Categoria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center">
            {isLoading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
          {!isLoading && (
            <>
              <div className="row">
                
                <div className="form-group mb-3">
                  <label htmlFor="name">Titulo</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Insira o titulo"
                    value={title}
                  readOnly/>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center w-100">
            <div className="d-block me-auto"></div>
            <div className="d-block ms-auto">
              <button
                className="btn btn-danger text-white me-2"
                onClick={handleClose}
              >
                Close
              </button>
             
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalPostCategoryInfo;