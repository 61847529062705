import React from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import styles from "./styles.module.css";

import BaseApi from "../../../services/Api";

import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { findIndex } from "lodash";
import ModalService from "../../../components/Modals/ModalService";
import ModalServiceInfo from "../../../components/Modals/ModalService/info";
import Pagination from "../../../components/Layout/Pagination";
import { Spinner } from "react-bootstrap";
import TableContainer from "../../../components/Layout/TableContainer";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

const INITIAL_DATA = {
  total: 0,
  current_page: 1,
  last_page: 1,
  first_page_url: "",
  last_page_url: "",
  next_page_url: "",
  prev_page_url: null,
  path: "",
  from: 1,
  to: 1,
  data: [],
  neverRequested: true,
};

export default function Services() {
  let [searchParams, setSearchParams] = useSearchParams();

  let controller = new AbortController();

  const INITIAL_FILTERS = {
    search: searchParams.get("search") || "",
  };

  const INITIAL_QUERY = {
    sort: "id",
    order: "desc",
    per_page: 10,
    page: searchParams.get("page") || 1,
  };

  const [isLoading, setLoading] = React.useState(true);
  const [isFiltering, setFiltering] = React.useState(true);
  const [isPaginating, setPaginating] = React.useState(true);
  const loggedUser = useOutletContext();

  const [query, setQuery] = React.useState({
    ...INITIAL_QUERY,
    ...INITIAL_FILTERS,
  });
  const [filters, setFilters] = React.useState({ ...INITIAL_FILTERS });
  const [tableData, setTableData] = React.useState({ ...INITIAL_DATA });

  const requestData = (args = {}) => {
    setLoading(true);
    let q = { ...query, ...args };
    BaseApi.get("/services", {
      signal: controller.signal,
      params: {
        ...q,
        search: q.search !== "" ? q.search : undefined,
      },
    })
      .then((response) => {
        setTableData(response.data);
        setLoading(false);
        setPaginating(false);
        setFiltering(false);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          toast.error("Erro ao carregar dados da tabela");
          setTableData({ ...INITIAL_DATA });
          setLoading(false);
          setPaginating(false);
          setFiltering(false);
        }
      });
  };

  const setSearch = (args = {}) => {
    let params = { ...args };
    if (filters.search && filters.search !== "") params.q = filters.search;
    setSearchParams(params);
  };

  const handlePagination = (page) => {
    setSearch({ p: page });
    setPaginating(true);
    setQuery({ ...query, page });
  };

  const handleFilters = (e) => {
    e.preventDefault();
    setSearch();
    setFiltering(true);
    setQuery({ ...query, ...filters, page: 1 });
  };

  const handleCreateService = (service) => {
    setLoading(true);
    setFilters({ ...INITIAL_FILTERS });
    setQuery({ ...INITIAL_QUERY });
  };

  const handleUpdateService = (service) => {
    let data = [...tableData.data];
    let toUpdate = findIndex(data, { id: service.id });
    if (toUpdate === -1) return;
    data[toUpdate] = { ...data[toUpdate], ...service };
    setTableData({ ...tableData, data: data });
  };

  const onDelete = (service) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar esse serviço?",
      text: "Essa ação não pode ser revertida!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, delete!",
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        setLoading(true);
        BaseApi.delete(`/services/${service.id}`)
          .then((res) => {
            toast.success("Serviço Deletado com sucesso!");
            requestData();
          })
          .catch((err) => {
            Swal.fire(
              "Oops!",
              err?.data?.errors?.[0] ||
                err?.data?.message ||
                "Ocorreu um erro ao deletar este serviço.",
              "error"
            );
            setLoading(false);
          })
          .finally(() => setLoading(false));
      }
    });
  };

  useEffect(() => {
    controller = new AbortController();
    requestData();
    return () => {
      controller.abort();
      setLoading(true);
    };
  }, [query]);

  return (
    <>
      <div className="d-flex flex-column">
        <div className={"d-flex flex-column flex-md-row pb-4"}>
          <div className={`${styles.service} col-12 col-md-4 mb-2`}>
            <h1>Serviços</h1>
          </div>
          <div className="col-12 col-md-6 mb-2">
            <form onSubmit={handleFilters} className="d-flex flex-row">
              <input
                type="text"
                className="form-control "
                placeholder="Pesquisa"
                aria-label="Pesquisa"
                value={filters.search}
                onChange={(e) =>
                  setFilters({ ...filters, search: e.target.value })
                }
              />
              <button className="btn btn-outline-success ms-2" type="submit">
                <span>Pesquisa</span>
              </button>
            </form>
          </div>
          <div className="col-12 col-md-2 d-flex justify-content-end mb-2">
            <ModalService onCreate={handleCreateService}>
              <button className="btn btn-outline-success ms-2" type="button">
                <span>Criar</span>
              </button>
            </ModalService>
          </div>
        </div>
        <TableContainer>
        {isLoading ||
          isPaginating ||
          isFiltering ||
          tableData.neverRequested ? (
            <div className="d-flex align-items-center justify-content-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : 

          (<Pagination
            onPaginate={handlePagination}
            showOnBottom={!isPaginating && tableData.data.length > 0}
            showOnTop={tableData.data.length > 0}
            paginateData={tableData}
          >
            {!isPaginating && (
              <>
                <div className={styles.table_container}>
                  <table className={`table`}>
                    <thead className={`${isLoading ? "d-none" : ""}`}>
                      <tr>
                        <th>ID</th>
                        <th>Título</th>
                        <th>Data de Criação</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.data.map((item) => (
                        <tr>
                          <td>{item.id}</td>
                          <td>{item.title}</td>
                          <td>
                            {format(
                              new Date(item.created_at),
                              "d 'de' MMMM 'de' yyyy 'às' H:mm",
                              { locale: ptBR }
                            )}
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <ModalService
                                idService={item.id}
                                onUpdate={handleUpdateService}
                              />
                              &nbsp;
                              <ModalServiceInfo idService={item.id}>
                                <button type="button" class="btn btn-primary">
                                  <i class="bi bi-info-circle"></i>
                                </button>
                              </ModalServiceInfo>
                              &nbsp;
                              <button
                                className="btn btn-danger"
                                onClick={() => onDelete(item)}
                              >
                                <i className="bi bi-trash-fill" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {tableData.data.length === 0 && (
              <h5 className="text-purple-3 text-center">
                Não foram encontrados registros com estes filtros.
              </h5>
            )}
          </Pagination>)}
        </TableContainer>
      </div>
    </>
  );
}
