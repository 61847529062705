import BaseApi from "../../../services/Api";
import { imageCompress } from "../../../services/ImageCompress";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import React, { useEffect, useState, useRef } from "react";
import Tooltip from "../../Popups/Tooltip";
import { Modal, Spinner } from "react-bootstrap";
import Button from "../../Layout/Button";
import style from "./style.module.css";
import TextEditor from "../../TextEditor";
import { htmlToText } from "../../../services/formatter"

const ModalPost = ({ slugPost, idPost, onUpdate, onCreate, children }) => {
  const [isLoading, setLoading] = React.useState(true);
  const [isSaving, setSaving] = React.useState(true);
  const [isShow, setShowModal] = React.useState(false);

  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [keywords, setKeyWords] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [updateImage, setUpdateImage] = useState(null);
  const [text, setText] = useState("");
  const [category, setCategory] = useState("");
  const [manualSlug, setManualSlug] = useState(false);
  const [errors, setErrors] = useState({});

  const [tabledata, setTableData] = useState(null);

  const buildFormData = async () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("slug", slug);
    formData.append("keywords", keywords);
    formData.append("description", description);
    if (htmlToText(text) === "") {
      formData.append("text", "")
    }
    else {
      formData.append("text", text);
    }
    formData.append("category_id", category);
    
    if (idPost) {
      formData.append("_method", "PUT");
      if (updateImage) {
        formData.append("image", await imageCompress(updateImage));
      }
    } else {
      if (image) {
        formData.append("image", await imageCompress(image));
      }
    }
    return formData;
  };
  
  const submitData = () => {
    setSaving(true);
    buildFormData().then((formData) => {
      if (idPost) {
        BaseApi.post(`/posts/${idPost}`, formData)
        .then((res) => {
          setSaving(false);
          setShowModal(false);
          toast.success("Post atualizado com sucesso!");
          onUpdate && onUpdate(res.data);
        })
          .catch((err) => {
            setErrors(err.response.data.errors);
            Swal.fire(
              "Oops!",
              err?.data?.errors?.[0] ||
              err?.data?.message ||
              "Ocorreu um erro ao atualizar este usuário.",
              "error"
              );
              setSaving(false);
            });
      } else {
        BaseApi.post("/posts", formData)
          .then((res) => {
            setSaving(false);
            setShowModal(false);
            toast.success("Post criado com sucesso");
            onCreate && onCreate(res.data);
          })
          .catch((err) => {
            setErrors(err.response.data.errors);
            Swal.fire(
              "Oops!",
              err?.data?.errors?.[0] ||
                err?.data?.message ||
                "Ocorreu um erro ao criar este post.",
              "error"
            );

            setSaving(false);
          });
      }
    });
  };

  const handleClose = () => {
    setShowModal(false);
    setManualSlug(false);
  };

  const handleChangeImage = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      if (idPost) {
        setUpdateImage(selectedImage);
      }
      setImage(selectedImage);
    }
  };

  const requestData = () => {
    setLoading(true);
    BaseApi.get(`/posts${idPost ? `/${slugPost}` : ""}`)
      .then((res) => {
        if (idPost) {
          let data = res.data;
          setTitle(data.title);
          setSlug(data.slug);
          setKeyWords(data.keywords);
          setDescription(data.description);
          setImage(data.image);
          setText(data.text);
          setCategory(data.category_id);
        }
        setLoading(false);
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Oops!",
          err?.data?.errors?.[0] ||
            err?.data?.message ||
            "Ocorreu um erro ao carregar este post ou não foi encontrado.",
          "error"
        );
        setShowModal(false);
      });
  };

  const requestDataCategory = () => {
    setLoading(true);
    BaseApi.get(`/get-post-category`)
      .then((res) => {
        setTableData(res);
        setLoading(false);
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Oops!",
          err?.dataCategory?.errors?.[0] ||
            err?.dataCategory?.message ||
            "Ocorreu um erro ao carregar este post ou não foi encontrado.",
          "error"
        );
        setShowModal(false);
      });
  };

  useEffect(() => {
    if (isShow) {
      requestDataCategory();
      requestData();
    } else {
      setTitle("");
      setSlug("");
      setKeyWords("");
      setDescription("");
      setImage(null);
      setUpdateImage(null);
      setText("");
      setCategory("");
      setLoading(true);
      setSaving(true);
      setErrors({});
    }
  }, [isShow]);

  const convertToSlug = (text) => {
    const normalizedText = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let slug = normalizedText
      .toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");

    if (slug.slice(-1) === "-") slug = slug.slice(0, -1);
    if (slug.charAt(0) === "-") slug = slug.slice(1);

    return slug;
  };

  const handleChangeCheckSlug = (e) => {
    setManualSlug(e.target.checked);
    if (!e.target.checked) setSlug(convertToSlug(title));
  };

  const handleSlugChange = (e) => {
    let rawValue = e.target.value;

    rawValue = rawValue.replace(/\s+/g, '-');

    const formattedSlug = rawValue
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, '');

    if (e.key === 'Backspace') {
      e.preventDefault();
      setSlug((prevSlug) => prevSlug.slice(0, -1));
    } else {
      setSlug(formattedSlug);
    }
  };

  return (
    <>
      {children &&
        React.cloneElement(children, { onClick: (e) => setShowModal(true) })}
      {!children && (
        <Tooltip text={idPost ? "Editar Post" : "Criar Post"}>
          <button
            className={`btn btn-${idPost ? "warning" : ""} text-white fa-bold`}
            onClick={(e) => setShowModal(true)}
          >
            <i className={`bi bi-${idPost ? "person-gear" : "plus"}`}></i>
          </button>
        </Tooltip>
      )}
      <Modal
        centered
        scrollable
        onHide={handleClose}
        show={isShow}
        animation={true}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{idPost ? "Editar" : "Criar"} Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center">
            {isLoading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
          {!isLoading && (
            <>
              <div className="row">
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="title">
                    Titulo{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Insira o titulo"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      if (!manualSlug) setSlug(convertToSlug(e.target.value));
                    }}
                    maxLength={80}
                  />
                  {errors?.title && (
                    <p style={{ color: "red" }}>{errors?.title.slice(-1)}</p>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="slug">
                    Slug{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="slug"
                    placeholder="Insira o Slug"
                    value={slug}
                    onChange={handleSlugChange}
                    disabled={!manualSlug}
                    maxLength={80}
                  />
                  {errors?.slug && (
                    <p style={{ color: "red" }}>{errors?.slug.slice(-1)} <span className="text-xs">Ex: 'lorens-ipsun'</span></p>
                  )}
                </div>
                <div className="form-group d-flex align-center mb-3">
                  <input
                    type="checkbox"
                    id="bannerbutton"
                    style={{ marginRight: "5px", width: "15px" }}
                    onChange={handleChangeCheckSlug}
                  />
                  <label htmlFor="bannerbutton">
                    Inserir slug manualmente?
                  </label>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="keywords">Palavras chave </label>
                  <input
                    type="text"
                    className="form-control"
                    id="keywords"
                    placeholder="Insira as palavras chave"
                    value={keywords}
                    onChange={(e) => setKeyWords(e.target.value)}
                    maxLength={150}
                  />
                  {errors?.keywords && (
                    <p style={{ color: "red" }}>{errors?.keywords.slice(-1)}</p>
                  )}
                  <p style={{ fontSize: "12px" }}>
                    As palavras chaves devem ser separadas apenas um por espaço
                    comum.
                  </p>
                </div>
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="description">
                    Descrição{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder="Insira a descrição"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    maxLength={200}
                  />
                  {errors?.description && (
                    <p style={{ color: "red" }}>
                      {errors?.description.slice(-1)}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="text">
                    Categoria da postagem{" "}
                  </label>
                  <select
                    className={style.selectcategory}
                    onChange={(e) => setCategory(e.target.value)}
                    value={category}
                  >
                    <option value="">Selecione uma categoria</option>
                    {tabledata.data.map((item) => (
                      <option className={style.required} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                  {errors?.category_id && (
                    <p style={{ color: "red" }}>{errors?.category_id.slice(-1)}</p>
                  )}
                  <p style={{ fontSize: "12px" }}>
                    Para inserir uma nova categoria acessa a seção Categoria de
                    Posts.
                  </p>
                </div>

                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="image">
                    Imagem{" "}
                  </label>

                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    onChange={handleChangeImage}
                  />
                  {errors?.image && (
                    <p style={{ color: "red" }}>{errors?.image.slice(-1)}</p>
                  )}

                  {idPost ? (
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      <img
                        src={
                          image instanceof File
                            ? URL.createObjectURL(image)
                            : image
                        }
                        alt="imagem"
                        style={{
                          minWidth: 250,
                          width: 350,
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  ) : null}

                  {image && !idPost && (
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      {idPost ? (
                        <img
                          src={image}
                          alt="imagem"
                          style={{
                            minWidth: 250,
                            width: 350,
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(image)}
                          alt="imagem"
                          style={{
                            minWidth: 250,
                            width: 350,
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="text">
                    Texto{" "}
                  </label>
                  <TextEditor
                    initialValue={(idPost) ? text : "<p></p>"}
                    onChange={setText}                    
                  />
                  {errors?.text && (
                    <p style={{ color: "red" }}>{errors?.text.slice(-1)}</p>
                  )}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center w-100">
            <div className="d-block me-auto"></div>
            <div className="d-block ms-auto">
              <button
                className="btn btn-danger text-white me-2"
                onClick={handleClose}
              >
                Close
              </button>
              <Button
                loading={isSaving}
                onClick={submitData}
                className="btn btn-success text-white"
              >
                {idPost ? "Editar" : "Criar"}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalPost;
