import {createBrowserRouter} from "react-router-dom";
import {Navigate} from "react-router-dom";
import DefaultLayout from "../pages/layouts/DefaultLayout";
import Dashboard from "../pages/Private/Dashboard";
import Users from "../pages/Private/Users";
import Partners from "../pages/Private/Partners";
import Services from "../pages/Private/Services";
import Testimonies from "../pages/Private/Testimonies";
import GuestLayout from "../pages/layouts/GuestLayout";
import Login from "../pages/Public/Login";
import Contacts from "../pages/Private/Contacts";
import AboutUs from "../pages/Private/AboutUs";
import Banners from "../pages/Private/Banners";
import InformationsCompanies from "../pages/Private/InformationsCompanies";
import NotFound from "../pages/Public/NotFound";
import IndexPage from "../pages/Public/IndexPage"
import PostCategory from "../pages/Private/PostCategory";
import Posts from "../pages/Private/Post"


const router = createBrowserRouter([
  {
    path: '/admin',
    element: <DefaultLayout />,
    children: [
      {
        path: '/admin',
        element: <Navigate to="/admin/dashboard" />,
        abstract: true,
        title: 'Dashboard',
        description: 'Dashboard',
      },
      {
        path: '/admin/dashboard',
        element: <Dashboard />,
      },
      {
        path: '/admin/users',
        element: <Users />,
      },
      {
        path: '/admin/parceiros',
        element: <Partners />,
      },
      {
        path: '/admin/services',
        element: <Services />,
      },
      {
        path: '/admin/testimonies',
        element: <Testimonies />,
      },
      {
        path: '/admin/banners',
        element: <Banners />,
      },
      {
        path: '/admin/informations_companies',
        element: <InformationsCompanies />,
      },
      {
        path: '/admin/post-category',
        element: <PostCategory />,
      },
      {
        path: '/admin/post',
        element: <Posts />,
      },
      {
        path:'/admin/contacts',
        element: <Contacts />,
      },
      {
        path:'/admin/about-us',
        element: <AboutUs />,
      },
    ]
  },
  {
    path: '/admin',
    element: <GuestLayout />,
    children: [
      {
        path: '/admin/login',
        element: <Login />,
      },
      // {
      //   path: '/admin/signup',
      //   element: <Signup />,
      // },
    ]
  },
  {
    path: '/',
    element: <Navigate to="/admin" />
  },
  {
    path: '*',
    element: <NotFound />,
  }


]);

export default router;
