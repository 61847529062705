import BaseApi from "../../../services/Api";
import Swal from "sweetalert2";
import React, {useEffect, useState} from "react";
import Tooltip from "../../Popups/Tooltip";
import {Modal, Spinner} from "react-bootstrap";
import { format } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR';


const ModalContactInfo = ({
  idContact, children
}) => {
  const [isLoading, setLoading] = React.useState(true);
  const [isSaving, setSaving] = React.useState(true);
  const [isShow, setShowModal] = React.useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [creationDate, setCreationDate] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [message, setMessage] = useState('');


  const handleClose = () => {
    setShowModal(false);
  }

  const requestData = () => {
    setLoading(true);
    BaseApi.get(`/contacts/${idContact}`).then(res => {
      let data = res.data;
      console.log(data);
      setName(data.name);
      setEmail(data.email);
      setCellphone(data.cellphone);
      setMessage(data.message);
      setCreationDate(data.created_at);
      setLoading(false);
      setSaving(false);
    }).catch(err => {
      console.log(err);
      Swal.fire('Oops!', err?.data?.errors?.[0] || err?.data?.message || 'Ocorreu um erro ao carregar este usuario ou o usuário não foi encontrado.', 'error');
      setShowModal(false);
    })
  }

  useEffect(() => {
    if(isShow) {
      requestData();
    } else {
      setName('');
      setEmail('');
      setCellphone('');
      setMessage('');
      setLoading(true);
      setSaving(true);
    }
  }, [isShow])

  return (
    <>
      {children &&
        React.cloneElement(children, { onClick: (e) => setShowModal(true) })}
      {!children && (
        <Tooltip text={"Informações do Contato"}>
          <button
            className={`btn btn-${idContact ? "warning" : ""} text-white fa-bold`}
            onClick={(e) => setShowModal(true)}
          >
            <i className={`bi bi-${idContact ? "person-gear" : "plus"}`}></i>
          </button>
        </Tooltip>
      )}
      <Modal
        centered
        scrollable
        onHide={handleClose}
        show={isShow}
        animation={true}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Informações do Contato</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center">
            {isLoading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
          {!isLoading && (
            <>
              <div className="row">
                
                <div className="form-group mb-3">
                  <label htmlFor="name">Nome</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Insira o nome"
                    value={name}
                  readonly/>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Insira o email"
                    value={email}
                   
                  readonly/>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="name">Telefone</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Insira o nome"
                    value={cellphone}
                  readonly/>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="name">Mensagem</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Insira o nome"
                    disabled
                    readonly>{message}</textarea>
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="creationDate">Data de criação</label>
                  <input
                    type="creationDate"
                    className="form-control"
                    id="creationDate"
                    value={format(new Date(creationDate), "d 'de' MMMM 'de' yyyy 'às' H:mm", { locale: ptBR })}
                   
                  readonly/>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center w-100">
            <div className="d-block me-auto"></div>
            <div className="d-block ms-auto">
              <button
                className="btn btn-danger text-white me-2"
                onClick={handleClose}
              >
                Close
              </button>
             
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalContactInfo;