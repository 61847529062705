import BaseApi from "../../../services/Api";
import { imageCompress } from "../../../services/ImageCompress";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import Tooltip from "../../Popups/Tooltip";
import { Modal, Spinner } from "react-bootstrap";
import Button from "../../Layout/Button";
import style from "./style.module.css";

const ModalUser = ({ idUser, onUpdate, onCreate, children, loggedUser }) => {
  const [isLoading, setLoading] = React.useState(true);
  const [isSaving, setSaving] = React.useState(true);
  const [isShow, setShowModal] = React.useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState(null);
  const [isAdmin, setIsAdmin] = useState();
  const [updateImage, setUpdateImage] = useState(null);
  const [password, setPassword] = React.useState("");
  const [passwordConfirmation, setPasswordConfirmation] = React.useState("");
  const [errors, setErrors] = useState({});

  const buildFormData = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);

    if (isAdmin) {
      formData.append("is_admin", 1);
    } else {
      formData.append("is_admin", 0);
    }

    if(password) {
      formData.append("password", password);
      formData.append("password_confirmation", passwordConfirmation);
    }

    if (idUser) {
      formData.append("_method", "PUT");
      if (updateImage) {
        formData.append("image", await imageCompress(updateImage));
      }
    } else {
      if (image) {
        formData.append("image", await imageCompress(image));
      }
    }
    return formData;
  };

  const submitData = () => {
    setSaving(true);
    buildFormData().then((formData) => {
      console.log(formData)
      if (idUser) {
        BaseApi.post(`/users/${idUser}`, formData)
          .then((res) => {
            setSaving(false);
            setShowModal(false);
            toast.success("Usuario criado com sucesso!");
            onUpdate && onUpdate(res.data);
          })
          .catch((err) => {
            setErrors(err.response.data.errors);
            Swal.fire(
              "Oops!",
              err?.data?.errors?.[0] ||
              err?.data?.message ||
              "Ocorreu um erro ao atualizar este usuário.",
              "error"
            );
            setSaving(false);
          });
      } else {
        BaseApi.post("/users", formData)
          .then((res) => {
            setSaving(false);
            setShowModal(false);
            toast.success("Usuario atualizado com sucesso");
            onCreate && onCreate(res.data);
          })
          .catch((err) => {
            setErrors(err.response.data.errors);
            Swal.fire(
              "Oops!",
              err?.data?.errors?.[0] ||
              err?.data?.message ||
              "Ocorreu um erro ao criar este usuário.",
              "error"
            );

            setSaving(false);
          });
      }
    });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleChangeImage = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      if (idUser) {
        setUpdateImage(selectedImage);
      }
      setImage(selectedImage);
    }
  };

  const handleButtonCheck = (e) => {
    setIsAdmin(e.target.checked);
    if (!e.target.checked) {
      setIsAdmin(false);
    }
  };

  const requestData = () => {
    setLoading(true);
    BaseApi.get(`/users${idUser ? `/${idUser}` : ""}`)
      .then((res) => {
        if (idUser) {
          let data = res.data;
          setName(data.name);
          setEmail(data.email);
          setImage(data.image);
          setIsAdmin(data.is_admin);
          setPassword(data.password);
          setPasswordConfirmation(data.password_confirmation);
        }
        setLoading(false);
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Oops!",
          err?.data?.errors?.[0] ||
          err?.data?.message ||
          "Ocorreu um erro ao carregar este usuário ou não foi encontrado.",
          "error"
        );
        setShowModal(false);
      });
  };

  useEffect(() => {
    if (isShow) {
      requestData();
    } else {
      setName("");
      setEmail("");
      setIsAdmin();
      setPassword("");
      setPasswordConfirmation("");
      setImage(null);
      setUpdateImage(null);
      setLoading(true);
      setSaving(true);
      setErrors({});
    }
  }, [isShow]);

  return (
    <>
      {children &&
        React.cloneElement(children, { onClick: (e) => setShowModal(true) })}
      {!children && (
        <Tooltip text={idUser ? "Editar usuário" : "Criar usuário"}>
          <button
            className={`btn btn-${idUser ? "warning" : ""} text-white fa-bold`}
            onClick={(e) => setShowModal(true)}
          >
            <i className={`bi bi-${idUser ? "person-gear" : "plus"}`}></i>
          </button>
        </Tooltip>
      )}
      <Modal
        centered
        scrollable
        onHide={handleClose}
        show={isShow}
        animation={true}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>{idUser ? "Editar" : "Criar"} usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center">
            {isLoading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
          {!isLoading && (
            <>
              <div className="row">
                {loggedUser.id !== idUser ? (
                  <div className="form-group d-flex align-center mb-3">
                    <input
                      type="checkbox"
                      checked={isAdmin}
                      id="is_admin"
                      style={{ marginRight: "5px", width: "15px" }}
                      onChange={handleButtonCheck}
                    />
                    <label htmlFor="is_admin">O usuário será administrador?</label>
                  </div>
                ) : ""}
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="name">
                    Nome{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Insira o nome"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {errors?.name && (
                    <p style={{ color: "red" }}>{errors?.name.slice(-1)}</p>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="email">
                    Email{" "}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Insira o email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors?.email && (
                    <p style={{ color: "red" }}>{errors?.email.slice(-1)}</p>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="password">
                    Senha{" "}
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errors?.password && (
                    <p style={{ color: "red" }}>{errors?.password.slice(-1)}</p>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="confirmPassword">
                    Confirme a Senha{" "}
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password_confirmation"
                    placeholder="Confirme a senha"
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                  />
                  {errors?.passwordConfirmation && (
                    <p style={{ color: "red" }}>
                      {errors?.passwordConfirmation.slice(-1)}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="image">
                    Imagem{" "}
                  </label>

                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    onChange={handleChangeImage}
                  />
                  {errors?.image && (
                    <p style={{ color: "red" }}>{errors?.image.slice(-1)}</p>
                  )}

                  {idUser ? (
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      <img
                        src={
                          image instanceof File
                            ? URL.createObjectURL(image)
                            : image
                        }
                        alt="imagem"
                        style={{
                          minWidth: 250,
                          width: 350,
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  ) : null}

                  {image && !idUser && (
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      {idUser ? (
                        <img
                          src={image}
                          alt="imagem"
                          style={{
                            minWidth: 250,
                            width: 350,
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(image)}
                          alt="imagem"
                          style={{
                            minWidth: 250,
                            width: 350,
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center w-100">
            <div className="d-block me-auto"></div>
            <div className="d-block ms-auto">
              <button
                className="btn btn-danger text-white me-2"
                onClick={handleClose}
              >
                Close
              </button>
              <Button
                loading={isSaving}
                onClick={submitData}
                className="btn btn-success text-white"
              >
                {idUser ? "Editar" : "Criar"}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalUser;
