import {useStateContext} from "../../context/ContextProvider";
import {Link, Navigate, Outlet} from "react-router-dom";
import BaseApi from "../../services/Api";
import {useEffect, useState} from "react";
import {Dropdown, Collapse} from "react-bootstrap";
import AvatarToggle from "../../components/AvatarToggle";
import style from "./style.module.css"
import Logo from "../../assets/images/logo.svg"


const DefaultLayout = () => {
  const [asideVisible, setAsideVisible] = useState(false);
  const {user, token, setUser, setToken} = useStateContext();

  useEffect(() => {
    token &&
    BaseApi.get('/user')
      .then((response) => {
        console.log('Response Data:', response.data);
        setUser(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  if (!token || !user) {
    return <Navigate to="/admin/login"/>
  }

  const onLogout = (ev) => {
    ev.preventDefault();
    BaseApi.post('/logout')
      .then(() => {
        setUser({});
        setToken(null);
      })
  }

  return (
    <div>
      <nav className="navbar" style={{backgroundColor: "#18355D"}}>
        
        <div className="container-lg" style={{minHeight: "40px"}}>
          <div className="d-flex">
            <div className="d-flex justify-content-center align-items-center me-2">
              <button className="d-lg-none" type="button" onClick={() => setAsideVisible(!asideVisible)}>
                {asideVisible ? <i className="bi bi-x"/> : <i className="bi bi-list"/>}
              </button>
            </div>

            <Link to="/admin/dashboard" className={style.logo} style={{fontWeight: "bold"}}>
              <img src={Logo} alt="logo" width="90" height="40"/>
            </Link>
          </div>
          <div>
            <div className={style.profile}>
              <Dropdown>
                <Dropdown.Toggle as={AvatarToggle} id="dropdown-custom-toggle">
                  <img src={user.image} className="object-fit-cover" style={{ aspectRatio: '1/1' }} alt=""/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.ItemText>
                    <div className="d-flex flex-column">
                      <span>
                      {user.name}
                    </span>
                      <span>
                      {user.email}
                    </span>
                    </div>
                  </Dropdown.ItemText>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#" onClick={onLogout}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </nav>
      <div id="defaultLayout">
        <aside className={`collapse d-lg-inline-block ${asideVisible ? 'show' : ''}`} style={{backgroundColor: "#18355D"}}>
          <Link to="/admin/dashboard" style={{fontWeight: "bold"}}>Painel de Controles</Link>
          <Link to="/admin/banners" style={{fontWeight: "bold"}}>Banners</Link>
          <Link to="/admin/testimonies" style={{fontWeight: "bold"}}>Depoimentos</Link>
          <Link to="/admin/services" style={{fontWeight: "bold"}}>Serviços</Link>
          <Link to="/admin/parceiros" style={{fontWeight: "bold"}}>Parceiros</Link>
          <Link to="/admin/about-us" style={{fontWeight: "bold"}}>Sobre Nós</Link>
          <Link to="/admin/post-category" style={{fontWeight: "bold"}}>Categoria de Posts</Link>
          <Link to="/admin/post" style={{fontWeight: "bold"}}>Posts</Link>
          <Link to="/admin/contacts" style={{fontWeight: "bold"}}>Contatos</Link>
          <Link to="/admin/users" style={{fontWeight: "bold"}}>Usuários</Link>
          <Link to="/admin/informations_companies" style={{fontWeight: "bold"}}>Informações da Empresa</Link>
        </aside>
        <div className="container-fluid mt-2 overflow overflow-x-auto">
          <header>
            <div>
              <h1>Painel de Controle</h1>
            </div>
          </header>
          <main>
            <Outlet context={user}/>
          </main>
        </div>
      </div>
    </div>
  )
}

export default DefaultLayout;