import BaseApi from "../../../services/Api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import Tooltip from "../../Popups/Tooltip";
import { Modal, Spinner } from "react-bootstrap";
import Button from "../../Layout/Button";
import style from "./style.module.css";

const ModalCategoryPost = ({ idCategory, onUpdate, onCreate, children }) => {
  const [isLoading, setLoading] = React.useState(true);
  const [isSaving, setSaving] = React.useState(true);
  const [isShow, setShowModal] = React.useState(false);

  const [title, setTitle] = useState("");
  const [errors, setErrors] = useState({});

  const buildFormData = () => {
    const formData = new FormData();
    formData.append("title", title);

    if (idCategory) {
      formData.append("_method", "PUT");
    }
    return formData;
  };

  const formData = buildFormData();

  const submitData = () => {
    setSaving(true);
    if (idCategory) {
      BaseApi.post(`/post-category/${idCategory}`, formData)
        .then((res) => {
          setSaving(false);
          setShowModal(false);
          toast.success("Categoria atualizada com sucesso!");
          onUpdate && onUpdate(res.data);
        })
        .catch((err) => {
          setErrors(err.response.data.errors);
          Swal.fire(
            "Oops!",
            err?.data?.errors?.[0] ||
              err?.data?.message ||
              "Ocorreu um erro ao atualizar esta categoria.",
            "error"
          );
          setSaving(false);
        });
    } else {
      BaseApi.post("/post-category", formData)
        .then((res) => {
          setSaving(false);
          setShowModal(false);
          toast.success("Categoria criada com sucesso");
          onCreate && onCreate(res.data);
        })
        .catch((err) => {
          setErrors(err.response.data.errors);
          Swal.fire(
            "Oops!",
            err?.data?.errors?.[0] ||
              err?.data?.message ||
              "Ocorreu um erro ao criar esta categoria.",
            "error"
          );

          setSaving(false);
        });
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const requestData = () => {
    setLoading(true);
    BaseApi.get(`/post-category${idCategory ? `/${idCategory}` : ""}`)
      .then((res) => {
        if (idCategory) {
          let data = res.data;
          setTitle(data.title);
        }
        setLoading(false);
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Oops!",
          err?.data?.errors?.[0] ||
            err?.data?.message ||
            "Ocorreu um erro ao carregar esta categoria de banner ou não foi encontrado.",
          "error"
        );
        setShowModal(false);
      });
  };

  useEffect(() => {
    if (isShow) {
      requestData();
    } else {
      setTitle("");
      setLoading(true);
      setSaving(true);
      setErrors({});
    }
  }, [isShow]);

  return (
    <>
      {children &&
        React.cloneElement(children, { onClick: (e) => setShowModal(true) })}
      {!children && (
        <Tooltip text={idCategory ? "Editar categoria" : "Criar categoria"}>
          <button
            className={`btn btn-${
              idCategory ? "warning" : ""
            } text-white fa-bold`}
            onClick={(e) => setShowModal(true)}
          >
            <i className={`bi bi-${idCategory ? "person-gear" : "plus"}`}></i>
          </button>
        </Tooltip>
      )}
      <Modal
        centered
        scrollable
        onHide={handleClose}
        show={isShow}
        animation={true}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>{idCategory ? "Editar" : "Criar"} Categoria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center">
            {isLoading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
          {!isLoading && (
            <>
              <div className="row">
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="name">
                    Titulo{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Insira a Categoria"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    maxLength={80}
                  />
                  {errors?.title && (
                    <p style={{ color: "red" }}>
                      {errors?.title.slice(-1)}
                    </p>
                  )}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center w-100">
            <div className="d-block me-auto"></div>
            <div className="d-block ms-auto">
              <button
                className="btn btn-danger text-white me-2"
                onClick={handleClose}
              >
                Close
              </button>
              <Button
                loading={isSaving}
                onClick={submitData}
                className="btn btn-success text-white"
              >
                {idCategory ? "Editar" : "Criar"}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalCategoryPost;
