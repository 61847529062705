import React from "react";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import style from "./styles.module.css";
import Button from "../../../components/Layout/Button";

import { phoneReplace } from "../../../services/formatter.js";
import BaseApi from "../../../services/Api";

import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { findIndex } from "lodash";
import { Spinner } from "react-bootstrap";
import { format, set } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

const INITIAL_DATA = {
  total: 0,
  current_page: 1,
  last_page: 1,
  first_page_url: "",
  last_page_url: "",
  next_page_url: "",
  prev_page_url: null,
  path: "",
  from: 1,
  to: 1,
  data: [],
};

export default function InformationsCompany() {
  let [searchParams, setSearchParams] = useSearchParams();

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [isSaving, setSaving] = React.useState(true);
  const [isShow, setShowModal] = React.useState(false);
  const [neverRequested, setNeverRequested] = React.useState(true);
  const [errors, setErrors] = useState({});
  const loggedUser = useOutletContext();

  const [idInformationsCompany, setIdInformationsCompany] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [localization, setLocalization] = useState("");
  const [working_hours, setWorkingHours] = useState("");
  const [description_footer, SetDescriptionFooter] = useState("");
  const [email, setEmail] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, SetFacebook] = useState("");
  const [x, SetX] = useState("");

  let controller = new AbortController();

  const INITIAL_FILTERS = {
    search: searchParams.get("search") || "",
  };

  const INITIAL_QUERY = {
    sort: "id",
    order: "desc",
    per_page: 10,
    page: searchParams.get("page") || 1,
  };

  const [query, setQuery] = React.useState({
    ...INITIAL_QUERY,
    ...INITIAL_FILTERS,
  });

  const requestData = () => {
    setLoading(true);
    BaseApi.get("/informations_company", {
      signal: controller.signal,
    })
      .then((response) => {
        setForm(response.data);
        setNeverRequested(false);
        setLoading(false);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          toast.error("Erro ao carregar dados da tabela");
          setLoading(false);
        }
      });
  };

  const setForm = (data) => {
    setIdInformationsCompany(data.id);
    setCellphone(data.cellphone);
    setLocalization(data.localization);
    setWorkingHours(data.working_hours);
    SetDescriptionFooter(data.description_footer);
    setEmail(data.email);
    setLinkedin(data.linkedin);
    setInstagram(data.instagram);
    SetFacebook(data.facebook);
    SetX(data.x);
  };

  const buildFormData = () => {
    const formData = new FormData();
    formData.append("cellphone", cellphone);
    formData.append("localization", localization);
    formData.append("working_hours", working_hours);
    formData.append("description_footer", description_footer);
    formData.append("email", email);
    formData.append("linkedin", linkedin);
    formData.append("instagram", instagram);
    formData.append("facebook", facebook);
    formData.append("x", x);
    formData.append("_method", "PUT");

    if (idInformationsCompany) {
      formData.append("_method", "PUT");
    } else {
      formData.append("_method", "POST");
    }

    return formData;
  };

  const formData = buildFormData();

  const submitData = () => {
    Swal.fire({
      title: "Tem certeza que deseja editar as informações da empresa?",
      text: "Essa ação não pode ser revertida!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Editar!",
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        let route = "/informations_company/";
        setLoading(true);
        if (idInformationsCompany) {
          route += idInformationsCompany;
        }
        setLoading(true);
        BaseApi.post(route, formData)
          .then((res) => {
            setErrors({});
            setSaving(false);
            setShowModal(false);
            requestData();
            setIsEditing(false);
            toast.success("Informações atualizadas com sucesso!");
          })
          .catch((err) => {
            setErrors(err.response.data.errors);
            Swal.fire(
              "Oops!",
              err?.data?.errors?.[0] ||
                err?.data?.message ||
                "Ocorreu um erro ao atualizar as informações.",
              "error"
            );
            setSaving(false);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    requestData();
  };

  useEffect(() => {
    controller = new AbortController();
    requestData();
    return () => {
      controller.abort();
      setLoading(true);
    };
  }, [query]);

  return (
    <>
      <div className="d-flex flex-column">
        <div className={"d-flex flex-column flex-md-row pb-4"}>
          <div className={`${style.user} col-12 col-md-4 mb-2`}>
            <h1>Informações da Empresa</h1>
          </div>
        </div>
        {isLoading || neverRequested ? (
          <div className="d-flex align-items-center justify-content-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="form-group mb-3">
                <label className={style.required} htmlFor="cellphone">
                  Telefone{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cellphone"
                  placeholder="Insira o telefone"
                  value={cellphone}
                  onChange={(e) => setCellphone(phoneReplace(e.target.value))}
                  readOnly={isEditing ? "" : "readonly"}
                />
                <p style={{ fontSize: "11px" }}>
                    Esse número também é usado como Whatsapp.
                </p>
                {errors?.cellphone && (
                  <p style={{ color: "red" }}>{errors?.cellphone.slice(-1)}</p>
                )}
              </div>
              <div className="form-group mb-3">
                <label className={style.required} htmlFor="localization">
                  Localização{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="localization"
                  placeholder="Insira o Localização"
                  value={localization}
                  onChange={(e) => setLocalization(e.target.value)}
                  readOnly={isEditing ? "" : "readonly"}
                />
                {errors?.localization && (
                  <p style={{ color: "red" }}>
                    {errors?.localization.slice(-1)}
                  </p>
                )}
              </div>
              <div className="form-group mb-3">
                <label className={style.required} htmlFor="working_hours">
                  Horas de Funcionamento{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="working_hours"
                  placeholder="Insira as Horas de Funcionamento"
                  value={working_hours}
                  onChange={(e) => setWorkingHours(e.target.value)}
                  readOnly={isEditing ? "" : "readonly"}
                />
                {errors?.working_hours && (
                  <p style={{ color: "red" }}>
                    {errors?.working_hours.slice(-1)}
                  </p>
                )}
              </div>
              <div className="form-group mb-3">
                <label className={style.required} htmlFor="description_footer">
                  Descrição do Rodapé{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="description_footer"
                  placeholder="Insira a Descrição do Rodapé"
                  value={description_footer}
                  onChange={(e) => SetDescriptionFooter(e.target.value)}
                  readOnly={isEditing ? "" : "readonly"}
                />
                {errors?.description_footer && (
                  <p style={{ color: "red" }}>
                    {errors?.description_footer.slice(-1)}
                  </p>
                )}
              </div>
              <div className="form-group mb-3">
                <label className={style.required} htmlFor="email">
                  Email{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="Insira o email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  readOnly={isEditing ? "" : "readonly"}
                />
                {errors?.email && (
                  <p style={{ color: "red" }}>{errors?.email.slice(-1)}</p>
                )}
              </div>
              <div className="form-group mb-3">
                <label htmlFor="linkedin">Linkedin </label>
                <input
                  type="text"
                  className="form-control"
                  id="linkedin"
                  placeholder="Insira o linkedin"
                  value={linkedin}
                  onChange={(e) => setLinkedin(e.target.value)}
                  readOnly={isEditing ? "" : "readonly"}
                />
                {errors?.linkedin && (
                  <p style={{ color: "red" }}>{errors?.linkedin.slice(-1)}</p>
                )}
              </div>
              <div className="form-group mb-3">
                <label htmlFor="instagram">Instagram </label>
                <input
                  type="text"
                  className="form-control"
                  id="instagram"
                  placeholder="Insira o instagram"
                  value={instagram}
                  onChange={(e) => setInstagram(e.target.value)}
                  readOnly={isEditing ? "" : "readonly"}
                />
                {errors?.instagram && (
                  <p style={{ color: "red" }}>{errors?.instagram.slice(-1)}</p>
                )}
              </div>
              <div className="form-group mb-3">
                <label htmlFor="facebook">Facebook</label>
                <input
                  type="text"
                  className="form-control"
                  id="facebook"
                  placeholder="Insira o facebook"
                  value={facebook}
                  onChange={(e) => SetFacebook(e.target.value)}
                  readOnly={isEditing ? "" : "readonly"}
                />
                {errors?.facebook && (
                  <p style={{ color: "red" }}>{errors?.facebook.slice(-1)}</p>
                )}
              </div>
              <div className="form-group mb-3">
                <label htmlFor="x">X </label>
                <input
                  type="text"
                  className="form-control"
                  id="x"
                  placeholder="Insira o x"
                  value={x}
                  onChange={(e) => SetX(e.target.value)}
                  readOnly={isEditing ? "" : "readonly"}
                />
                {errors?.x && (
                  <p style={{ color: "red" }}>{errors?.x.slice(-1)}</p>
                )}
              </div>

              {!isEditing ?
                  <div className="d-flex justify-content-end">
                  <Button
                    loading={false}
                    onClick={handleEditClick}
                    className="btn btn-warning text-white"
                  >
                    Editar
                  </Button>
                </div>
              : (
                <>
                  <div class="d-flex justify-content-between">
                    <div class="d-flex justify-content-start col-10">
                      <Button
                        loading={false}
                        onClick={handleCancelClick}
                        className="btn btn-danger text-white"
                      >
                        Cancelar
                      </Button>
                    </div>
                    <div class="d-flex justify-content-end">
                      <Button
                        loading={false}
                        className="btn btn-success text-white"
                        onClick={submitData}
                      >
                        Salvar
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
