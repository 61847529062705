import BaseApi from "../../../services/Api";
import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import Tooltip from "../../Popups/Tooltip";
import { Modal, Spinner } from "react-bootstrap";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import style from "./style.module.css";

const ModalPostInfo = ({ slugPost, idPost, children }) => {
  const [isLoading, setLoading] = React.useState(true);
  const [isSaving, setSaving] = React.useState(true);
  const [isShow, setShowModal] = React.useState(false);

  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [keywords, setKeyWords] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [text, setText] = useState("");
  const [category, setCategory] = useState("");

  const handleClose = () => {
    setShowModal(false);
  };

  const requestData = () => {
    setLoading(true);
    BaseApi.get(`/posts/${slugPost}`)
      .then((res) => {
        let data = res.data;
        setTitle(data.title);
        setSlug(data.slug);
        setKeyWords(data.keywords);
        setDescription(data.description);
        setImage(data.image);
        setText(data.text);
        setCategory(data.category.title);
        setLoading(false);
        setSaving(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Oops!",
          err?.data?.errors?.[0] ||
            err?.data?.message ||
            "Ocorreu um erro ao carregar este post ou não foi encontrado.",
          "error"
        );
        setShowModal(false);
      });
  };

  useEffect(() => {
    if (isShow) {
      requestData();
      console.log(requestData)
    } else {
      setTitle("");
      setSlug("");
      setKeyWords("");
      setDescription("");
      setImage(null);
      setText("");
      setCategory(null);
      setLoading(true);
      setSaving(true);
    }
  }, [isShow]);

  return (
    <>
      {children &&
        React.cloneElement(children, { onClick: (e) => setShowModal(true) })}
      {!children && (
        <Tooltip text={"Informações do Post"}>
          <button
            className={`btn btn-${idPost ? "warning" : ""} text-white fa-bold`}
            onClick={(e) => setShowModal(true)}
          >
            <i className={`bi bi-${idPost ? "person-gear" : "plus"}`}></i>
          </button>
        </Tooltip>
      )}
      <Modal
        centered
        scrollable
        onHide={handleClose}
        show={isShow}
        animation={true}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Informações do Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center">
            {isLoading && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
          {!isLoading && (
            <>
              <div className="row">
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="title">
                    Titulo{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Insira o titulo"
                    value={title}
                    readOnly
                  />
                </div>
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="slug">
                    Slug{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="slug"
                    placeholder="Insira o Slug"
                    value={slug}
                    readOnly
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="keywords">
                    Palavras chave{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="keywords"
                    placeholder="Insira as palavras chave"
                    value={keywords}
                    readOnly
                  />
                </div>
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="description">
                    Descrição{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder="Insira a descrição"
                    value={description}
                    readOnly
                  />
                </div>

                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="category">Categoria da postagem</label>
                  <input
                    type="text"
                    className="form-control"
                    id="category"
                    placeholder="Insira a descrição"
                    value={category}
                    readOnly
                  />
                </div>

                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="image">
                    Imagem{" "}
                  </label>

                  {idPost ? (
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      <img
                        src={
                          image instanceof File
                            ? URL.createObjectURL(image)
                            : image
                        }
                        alt="imagem"
                        style={{
                          minWidth: 250,
                          width: 350,
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  ) : null}

                  {image && !idPost && (
                    <div className="d-flex justify-content-center align-items-center mt-2">
                      {idPost ? (
                        <img
                          src={image}
                          alt="imagem"
                          style={{
                            minWidth: 250,
                            width: 350,
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <img
                          src={URL.createObjectURL(image)}
                          alt="imagem"
                          style={{
                            minWidth: 250,
                            width: 350,
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className={style.required} htmlFor="text">
                    Texto{" "}
                  </label>
                  <div className="form-control" dangerouslySetInnerHTML={{ __html: text }}></div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center w-100">
            <div className="d-block me-auto"></div>
            <div className="d-block ms-auto">
              <button
                className="btn btn-danger text-white me-2"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalPostInfo;
